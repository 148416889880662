<template>
  <AuthLayout>
    <AuthLogin />
  </AuthLayout>
</template>
<script>
const AuthLayout = () => import('@layouts/auth.vue')
const AuthLogin = () => import('@components/auth/auth-login.vue')

export default {
  components: {
    AuthLayout,
    AuthLogin
  }
}
</script>
